@import "../../../styles/usfAppTheme.scss";

.search-text-field {
  width: 94%;
}
.search-btn {
  border-radius: unset;
  height: 35px;
}
.divider {
  margin-top: 5px;
}
.search-text-field > input {
  height: 2px !important;
}
.search-text-block {
  padding: 5px 0px 0px 0px;
}
.search-pat-btn {
  padding: 8px;
  background: #1BA3AD !important;
  color: white !important;
  border: 0;
  height: 40px;
  width: 47px;
  min-width: unset !important;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.menu-item-icon-color {
position: absolute;
width: 15.12px;
height: 15.13px;
left: calc(05% - 5.12px/2 + 0.01px);
top: calc(28% - 2.13px/2 + 8.44px);
border: 2px  #303030;
}
.circle-icon {
  margin-bottom: -5px;
  color: #1976d2;
}
.clinical-details-section {
  padding: 10px;
  background-color: $BackgroundWhiteColor;
}
.modal-stack {
  margin-top: 10px;
  align-items: $AlignItemsCenter;
  justify-content: $JustifyContentSpaceBetween;
}
.pad-10 {
  padding: 10px;
}
.pad-5 {
  padding: 5px;
}
.m-10 {
  margin: 10px;
}
.patient-dropdown {
  width: 90%;
  height: 35px;
}
.align-center {
  align-items: $AlignItemsCenter;
}
.m-l-40 {
  margin-left: 40px;
}
.m-l-20 {
  margin-left: 20px;
}
.grid-style {
  height: 400px;
  width: 100%;
  padding-top: 10px;
}
.grid-style2 {
  width: 100%;
  padding-top: 10px;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.down-arrow {
  margin-top: -3px;
  margin-left: 5px;
}
.teal-bg-color {
  background-color: $BackgroundTealGrayColor;
}
.mail-Icon {
  width: 9px;
  position: relative;
  top: 8px;
  left: 5px;
}
.m-r-m15 {
  margin-right: -15px !important;
}
.m-r-m10 {
  margin-right: -10px !important;
}

.display-none{
  display: none !important;
}
.directory-container{
  position: relative;
}
.patient-context{
  min-width: 310px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: #FFFFFF;
}
.refund-application{
  margin: 0px 12px !important;
}
.align-center {
  justify-items: center;
  align-items: center;
}
.word-wrap {
  white-space: break-spaces !important ;
}

.medcase-form{
  max-height: 70vh;
  width: 100%;
  overflow: auto;
}

.medcase-form-container{
  height: 70%;
  width: 100%;
  overflow: hidden;
}

.modal-style-medcase {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: $ModalBackgroundColor;
  padding: 10px;
  margin: 10px;
}

.m-l-8{
  margin-left: 8px !important;
}