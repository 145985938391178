.App {
  font-family: sans-serif;
  text-align: left;
}

.customTable {
  border-collapse: collapse;
  width: 100%;
  height: auto;
}

.customTable th {
  border-top: 1px solid #1BA3AD;
  border-bottom: 1px solid #1BA3AD;
  border-left: none;
  border-right: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 50px;

}

.customTable td {
  border: none;
}

.customTable td .custom-table-color {
  border-top: 1px solid #0000001f;
}
.customTable td  {
  border-top: 1px solid #0000001f;
}



th {
  border: 1px solid black;
}

td {
  border: 1px solid black;
}

td .custom-table-color {
  border-top: 1px solid black;
}