/* html {
    font: 12px "Lato", "Helvetica Neue", helvetica, arial, sans-serif;
    line-height: 1.5;
  } */
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.app {
  position: relative;
  max-width: 1920px;
  min-width: 1023px;
  height: 100vh;
  margin: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
a {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  color: #000;
}
.wrapper {
  display: flex;
}
.nav-global {
  justify-content: space-between;
  background: #1BA3AD;
  flex-shrink: 0;
  height: 80px;
  padding-left: 30px;
}
.nav-global-section,
.nav-utility {
  -ms-flex-align: center;
  align-items: center;
}
.list-no-style {
  list-style: none;
  padding: 0;
  margin: 0;
}
.nav-global-item {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 5px solid transparent;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.nav-global-item-link {
  position: relative;
  top: 3px;
  display: block;
  font-size: 14px;
  padding: 27px 5px;
  color: #fff !important;
  font-family: "Rubik";
}
.nav-utility-item {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin-right: 10px;
  margin-left: 10px;
}
.nav-utility-item-link {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: #fff !important;
  font-family: "Rubik";
  font-size: 14px;
  padding: 12px 5px;
}
.nav-utility-item-text {
  line-height: 1.5;
}
.nav-utility-item-link:before {
  font-size: 14px;
  margin-bottom: 10px;
}
.unread-indicator {
  display: inline-block;
  background: #d0db9a;
  border-radius: 50%;
  height: 8px;
  margin-right: 5px;
  width: 8px;
  margin-top: 2px;
}
.d-Flex {
  display: flex;
}
.text-center {
  text-align: center;
}
.m-r-50 {
  margin-right: 50px;
}
.alert {
  padding: 10px;
  background: #ffeaea !important;
  border-color: #d3080c;
  color: #b22222 !important;
  font-size: 14px !important;
  border-left: 6px solid;
  text-align: center;
}
.active {
  margin-top: 4px;
  border-bottom: 4px solid #ee2557;
}
.disable {
  pointer-events: none !important;
  opacity: 0.6 !important;
}
.nav-global-item-link-disable {
  position: relative;
  top: 3px;
  display: block;
  font-size: 14px;
  padding: 27px 5px;
  color: #C0C0C0 !important;
  font-family: "Rubik";
  pointer-events: "none" !important;
}
.nav-utility-item-link-disable {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: #C0C0C0 !important;
  font-family: "Rubik";
  font-size: 14px;
  padding: 12px 5px;
  pointer-events: "none" !important;
}
a.bread-hover {
  color: rgba(0, 0, 0, 0.6)
}