body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,body{
  font-family: 'Rubik';
}

button {
  font-family: "Economica" !important;
  text-transform: uppercase !important;
}
.MuiDataGrid-columnHeaderTitle {
  font-family: "Economica" !important;
  font-weight:  bold !important;
  font-size:  13px !important;
}