$ModalBackgroundColor: #fff;
$AlignItemsCenter: center;
.message-header {
  margin: 16px;
}
.flex-between {
  justify-content: space-between;
  align-items: center;
}

.font-24 {
  font-size: 24px !important;
}
.static-text {
  background: #f9f9f9;
  margin: 8px 0px;
}
.p-4 {
  padding: 4px;
}

.grid-style {
  height: 400px;
  width: 100%;
  padding-top: 10px;
}
.grid-style-Inbox {
  height: 265px;
  width: 100%;
  padding-top: 10px;
}

.message-body {
  margin: 1cm;
  width: 1000px;
  padding: 20px;
  border: 1px solid #ccc;
  border-top: 4px solid skyblue !important;
  border-radius: 5px;
}

.replied-message-style {
  margin: 1cm;
  width: 1000px;
  padding: 20px;
}

.textarea-style {
  margin-top: 20px;
  width: 51em;
}

.mt-15 {
  margin-top: 15px;
}

.mt-50 {
  margin-top: 50px;
}

.alert-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: $ModalBackgroundColor;
  padding: 20px;
}

.modal-divider {
  background-color: #1BA3AD;
}

.modal-stack {
  margin-top: 10px;
  align-items: $AlignItemsCenter;
  justify-content: space-between;
}

.modal-bottom-section {
  align-items: $AlignItemsCenter;
  justify-content: space-between;
  margin-top: 20px;
}

.modal_style_newMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $ModalBackgroundColor;
  overflow-y: scroll;
  height: 500px;
  width: 600px;
  padding: 25px;
}

.ql-editor {
  margin-left: -15px;
  min-height: 18em;
  width: 38.5em;
}
.ql-editor-reply {
  margin-left: 1px;
  min-height: 18em;
  width: 45em;
}

.compose-message-heading {
  margin: 25px;
  justify-content: space-between;
  align-items: center;
}

.replied-message-style {
  width: 800px;
  padding: 25px;
  padding-top: 0px !important;
  margin-left: -1px;
}

.mt-10 {
  margin-top: 10px;
}

.selected-message-content {
  width: 95%;
  border: 1px solid #ccc;
  border-top: 4px solid skyblue !important;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  margin-left: 20px !important;
}
.color-pink {
  color: #a6315c;
}

.color-blue {
  color: #00889f;
}
.disable-section {
  pointer-events: none;
  opacity: 0.4;
}
