$TextColorWhite:#fff;
$ModalBackgroundColor:#fff;
$BackgroundWhiteColor:#fff;
$GridHeight:400px;
$GridWidth:100%;
$AlignItemsCenter: center;
$TextAlignCenter:center;
$JustifyContentSpaceBetween:space-between;
$TextDecorationNone:none;
$BackgroundTealGrayColor:#F9F9F9;
$BackgroundLigthGray:#0000001f;

@import url('https://fonts.googleapis.com/css2?family=Economica:ital,wght@0,400;0,700;1,400;1,700&display=swap')

