@import "../../../styles/usfAppTheme.scss";

.modal-divider {
  background-color: #1BA3AD;
}

.financial-directory {
  background: #f9f9f9;
}
.estimate-details {
  background: #f9f9f9;
  margin: 0px 8px;
}

.p-8 {
  padding: 8px;
}
.m-8 {
  margin: 8px;
}

.mx-8 {
  padding: 0px 8px;
}
.align-center {
  justify-items: center;
  align-items: center;
}
.mt-8 {
  margin-top: 8px;
}

.p-4 {
  padding: 4px;
}
.align-between {
  justify-content: space-between;
  align-items: center;
}
.text-field {
  width: 72%;
}

.align-end {
  justify-content: end;
  align-items: center;
}
.container {
  position: relative;
}

.patient-demographic {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: #ffffff;
  box-shadow: 5px 0px 10px #232931;
}
.cursor-pointer {
  cursor: pointer;
}

.word-wrap {
  white-space: break-spaces !important ;
}

.publish-create-ellipsis {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.treatment-type-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.treatment-type {
  max-height: 60vh;
  width: 100%;
  overflow: auto;
}
.flex-between {
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.width-100 {
  min-width: 100%;
}
.addition-text {
  background: #f9f9f9;
  justify-content: center;
  margin: 8px;
}
.cost-coverage,
.edit-program-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  background-color: $ModalBackgroundColor;
  padding: 25px;
}
.add-btn {
  text-transform: none !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  font-style: normal;
  max-width: 200px;
}
.treatment-plan {
  padding: 8px 0px;
}
.adjustments {
  padding: 8px 0px;
}
.service-item-price {
  padding: 8px 0px;
}
.pgta-analysis {
  padding: 8px 0px;
}

.loan-section {
  background: #f9f9f9;
  padding: 8px;
  margin: 0px 8px;
}

.card-section {
  margin: 8px;
}
.go-to-application {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.remove-bottom-padding {
  padding: 8px !important;
  padding-bottom: 8px !important;
}

.pgta-card {
  border: 1px solid #1BA3AD !important;
}

.add-to-practice {
  background: #f9f9f9;
  padding: 15px 0px;
  margin-top: 16px;
}

.edit-icon {
  width: 14px;
  height: 14px;
}

.p-16 {
  padding: 16px;
}

.gap-2 {
  gap: 16px;
}

.box-arrow {
  padding: 8px 16px;
  box-shadow: -0px 5px #facfda;
  width: 30%;
  border-left: 5px solid #1BA3AD !important;
  background-color: #f9f9f9;
}

.m-l-r-12 {
  margin: 0px 12px;
}

.row-gap-8 {
  row-gap: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.m-t-b-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.m-t-b-8 {
  margin: 8px 0px;
}

.fap-icon {
  height: 50px;
  width: 252px;
}
.mt-16 {
  margin-top: 16px;
}
.program-summary {
  background: #facfda;
  padding: 10px;
  color: #a6315c;
}
.refund-cycle-card {
  background: #efefef;
  min-height: 180px;
}

.card-border {
  border: 2px solid #00889f !important;
}
.cards {
  position: absolute;
  bottom: 0px;
}
.mt-90 {
  margin-top: 90px !important;
}
.bt-radius-0 {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}
.link-text {
  font-size: 14px !important;
  line-height: 16px !important;
}
.compare-details {
  margin-top: 32px !important;
  padding: 10px;
  background: #a6315c;
  color: #ffffff;
  font-size: 14px;
}
.compare-details-items {
  padding: 20px;
  background: #efefef;
}
.grey-background {
  background: #efefef !important;
}

.card-left-border {
  box-shadow: -5px 0px 0px #00889f;
}

.card-bottom-border {
  box-shadow: 0px 5px 0px #facfda;
}

.loan-disclaimer {
  font-size: small;
  font-style: normal;
}

.overflow-visible {
  overflow: visible !important;
}

.box-btn {
  padding: 8px 16px;
  width: 30%;
  border: 1px solid whitesmoke;
}
.m-l-5 {
  margin-left: 5px !important;
}

.m-l-m5 {
  margin-left: -5px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.list-subheader {
  line-height: 24px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.add-circle {
  height: 0.8em !important;
  width: 0.8em !important;
}
.m-t-90 {
  margin-top: 90px !important;
}

.confirm-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 530px;
  background-color: $ModalBackgroundColor;
  padding: 20px;
}
.min-h-103 {
  min-height: 103px !important;
}
.fe-preview-overlay.left {
  top: 120px;
  bottom: 0;
  left: 0;
  -webkit-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
  width: 170px;
}
.fe-preview-overlay.left,
.fe-preview-overlay.right,
.fe-preview-overlay.top {
  position: absolute;
  top: 0;
  z-index: 999;
  background-color: #303030;
  opacity: 1;
}
.fe-preview-overlay.top {
  right: 15%;
  left: 0;
  height: 120px;
}
.fe-preview-overlay.right {
  width: 310px;
}
.fe-preview-overlay.right {
  right: 0;
  bottom: 0;
}
.bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #efefef;
  min-height: 180px;
}
.bar-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  margin: 1px;
  text-align: center;
  height: 80px;
  background: #b9e0e9;
  font-size: 12px !important;
  color: #00889f;
  align-self: flex-end;
}
.bar-direction {
  flex-direction: column;
}
.alignSelf {
  align-self: center;
}
.height-50 {
  height: 50px !important;
}
.effective-text {
  padding-left: 60px;
  padding-right: 60px;
  text-align: center;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #ffffff !important;
}
.pad-b-0 {
  padding-bottom: 0px !important;
}
.pad-t-0 {
  padding-top: 0px !important;
}
.note-for-no {
  margin-top: -10px !important;
  margin-left: 30px !important;
}
.fit-content {
  width: fit-content;
}
.asteriks {
  color: red;
}
.ack {
  margin-top: -31px;
  margin-left: 25px;
  color: black !important;
}
.outcome-form {
  width: 1300px;
  max-height: 500px;
  overflow-y: auto;
}
.disable-section {
  pointer-events: none;
  opacity: 0.4;
}
.MuiAutocomplete-input{
  padding: 0 !important;
}
.estimate-del-ico {
  padding: 0px !important;
}

.sidebar-container {
  span {
    font-family: "Economica";
    font-size: 14px !important;
  }
  width: 100%;
}