@import "../../../styles/usfAppTheme.scss";

.search-text-field {
  width: 94%;
}
.search-btn {
  border-radius: unset;
  height: 35px;
}
.divider {
  margin-top: 5px;
}
.search-text-field > input {
  height: 2px !important;
}
.search-text-block {
  padding: 5px 0px 0px 0px;
}
.search-pat-btn {
  padding: 8px;
  background: #1BA3AD !important;
  color: white !important;
  border: 0;
  height: 38px;
  width: 47px;
  min-width: unset !important;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.circle-icon {
  margin-bottom: -5px;
  color: #1976d2;
}
.clinical-details-section {
  padding: 10px;
  background-color: $BackgroundWhiteColor;
}
.modal-stack {
  margin-top: 10px;
  align-items: $AlignItemsCenter;
  justify-content: $JustifyContentSpaceBetween;
}
.pad-10 {
  padding: 10px;
}
.pad-5 {
  padding: 5px;
}
.m-10 {
  margin: 10px;
}
.patient-dropdown {
  width: 90%;
  height: 35px;
}
.align-center {
  align-items: $AlignItemsCenter;
}
.m-l-40 {
  margin-left: 40px;
}
.m-l-20 {
  margin-left: 20px;
}

.patient-form-grid {
  height: 200px;
  width: 100%;
  padding-top: 10px;
}
.grid-style {
  height: 400px;
  width: 100%;
  padding-top: 10px;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.down-arrow {
  margin-top: -3px;
  margin-left: 5px;
}
.teal-bg-color {
  background-color: $BackgroundTealGrayColor;
}
.mail-Icon {
  width: 9px;
  position: relative;
  top: 8px;
  left: 5px;
}
.m-r-m15 {
  margin-right: -15px !important;
}
.m-r-m10 {
  margin-right: -10px !important;
}
.m-t-m10 {
  margin-top: -10px !important;
}

.display-none {
  display: none !important;
}
.directory-container {
  position: relative;
}
.patient-context {
  min-width: 310px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: #ffffff;
}
.divider-height {
  height: 0.5px;
  background-color: #1BA3AD;
}
.pad-l-10 {
  padding-left: 10px;
}
.pad-t-15 {
  padding-top: 15px;
}
.pad-t-10 {
  padding-top: 10px;
}
.pad-r-10 {
  padding-right: 10px;
}
.pad-l-10 {
  padding-left: 10px;
}
.vertical-divider {
  border-left: 1.5px solid #e2e2e2;
}
.link-color {
  color: #1BA3AD !important;
}
.patient-card-details {
  margin: 0px 10px 10px 10px !important;
}
.add-patient-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: $ModalBackgroundColor;
  padding: 25px;
}
.patient-form-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.patient-form-content {
  max-height: 60vh;
  width: 100%;
  overflow: auto;
}

.grid-style {
  height: 400px;
  width: 100%;
  padding-top: 10px;
}
.add-contact,
.add-email {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: $ModalBackgroundColor;
  padding: 25px;
}
.add-address {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: $ModalBackgroundColor;
  padding: 25px;
}
.m-revert {
  margin: revert !important;
}
.flex-6 {
  flex: 6;
}
.flex-4 {
  flex: 4;
}
.pr-20 {
  padding-right: 20px !important;
}
.mr-8 {
  margin-right: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.patient-form {
  padding-left: 20px !important;
}
.relationship-info{
  margin-right: 8px;
  padding-left: 8px;
  margin-top: 8px;
  padding-bottom: 8px;
}
.mt-8{
  margin-top: 8px !important;
}

.disable-section {
  pointer-events: none;
  opacity: 0.4;
}