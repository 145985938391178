@import "../../../styles/usfAppTheme.scss";

.link {
  text-decoration: $TextDecorationNone;
  color: $TextColorWhite;
}

.grid-label {
  margin: 10px;
}

.btn-primary {
  border-radius: unset !important;
  cursor: pointer !important;
  border: 2px solid #177483;
}

.text {
  text-transform: none !important;
}

.flex-2 {
  flex: 2;
}

.flex-6 {
  flex: 6;
}

.ht-35 {
  height: 35px;
}

.grid-style {
  height: 400px;
  width: 100%;
  padding-top: 10px;
}

.modal-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 470px;
  background-color: $ModalBackgroundColor;
  padding: 20px;
}

.modal-style-prs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 750px;
  background-color: $ModalBackgroundColor;
  padding: 30px;
}

.modal-style-contact {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: $ModalBackgroundColor;
  padding: 20px;
}

.alert-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: $ModalBackgroundColor;
  padding: 10px;
}

.modal-divider {
  background-color: #1BA3AD;
}

.modal-bottom-section {
  align-items: $AlignItemsCenter;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-stack {
  margin-top: 10px;
  align-items: $AlignItemsCenter;
  justify-content: space-between;
}

.modal-stack-left {
  margin-top: 10px;
  align-items: flex-start;
  justify-content: space-between;
}

.modal-stackMedCase {
  align-items: $AlignItemsCenter;
  justify-content: space-between;
  padding: 5px;
}

.grid-header,
.loc-details-tabs,
.msg-modal {
  align-items: $AlignItemsCenter;
  justify-content: $JustifyContentSpaceBetween;
}

.m-r-8 {
  margin-right: 8px;
}

.m-l-8 {
  margin-left: 8px;
}

.practice-details {
  padding: 10px;
}

.pad-10 {
  padding: 10px;
}

.pad-0 {
  padding: 0px !important;
}

.practice-details,
.MuiBox-root.css-1gsv261 {
  border: unset !important;
}

.tab-panel-btn {
  margin-top: -30px !important;
  right: 10px;
  position: absolute !important;
}

.btn-edit-practice {
  position: absolute !important;
  right: 20px;
}

.practice-details-section,
.stage-details-section,
.patient-card-details {
  padding: 10px;
  background-color: $BackgroundTealGrayColor;
}

.create-user-role-section {
  border: 2px solid $BackgroundLigthGray;
  padding: 5px 10px 10px 5px;
  border-radius: 6px;
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.word-break {
  white-space: pre-line !important;
}

.tab-panel-btnn {
  margin-top: -30px !important;
  right: 13px;
  top: 34px;
  position: absolute !important;
}

.grid-style1 {
  height: $GridHeight;
  width: $GridWidth;
  padding-top: 0px;
}

.stage-practice-dropdown {
  /* display: block !important; */
  height: 35px;
}

.m-l-50 {
  margin-left: 40% !important;
}
.txt-center {
  text-align: center;
}

.stage-details {
  margin: 5px;
  padding: 10px;
  background-color: $BackgroundTealGrayColor;
}

.price-details {
  margin: 5px;
  padding: 10px;
  background-color: $BackgroundTealGrayColor;
}

.modal-style-stages {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 650px;
  background-color: $ModalBackgroundColor;
  padding: 20px;
}

.buttonToText {
  border: none;
  background: none;
}

.p-r-10 {
  padding-right: 10px !important;
}

.modal-style-delete {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  background-color: $ModalBackgroundColor;
  padding: 20px;
}

.background-grey {
  background-color: $BackgroundTealGrayColor;
}

.item-center {
  align-items: center;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.min-width-100 {
  min-width: 100% !important;
}

.textfield-background {
  background: #efefef;
}

.multiplier-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.multipler-items {
  max-height: 60vh;
  width: 100%;
  overflow: auto;
  padding-right: 10px;
}

.m-10 {
  margin: 10px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.grid-styles {
  height: 250px;
  width: 100%;
  padding-top: 10px;
}

.btn-right {
  position: flex;
  // left: 20px;
  align-items: flex-end;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #e3e3e3;
  border-top: 6px solid #3498db;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.bg-white {
  background: white;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.o-date-picker {
  height: 35px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-8 {
  margin-top: 8px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.contact-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.contact-form {
  max-height: 60vh;
  width: 100%;
  overflow: auto;
}

.pad-l-17 {
  padding-left: 17px;
}

.font-w-500 {
  font-weight: 500 !important;
}

.f-option {
  font-size: 12px !important;
  color: #8c8c8c;
}

.txt-capitalize {
  text-transform: capitalize !important;
}

.q-divider {
  border-color: #d6d6d6 !important;
  border-width: -0.9px !important;
}

.ht-30 {
  height: 30px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.scroll {
  max-height: 150px;
  height: 100;
  overflow-y: auto;
}

.modal-style-outcome {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $ModalBackgroundColor;
  padding: 20px;
  width: 900px;
  overflow-y: auto;
  max-height: 500px;
}

.accordion-style {
  background-color: #1BA3AD;
  margin: 2px;
  flex-grow: 1;
}

.accordion-summary-style {
  flex-direction: row-reverse;
  flex-grow: 0;
}

.dropdown-styles {
  flex: 6;
  height: 35px;
}

.outcome-grid-style {
  height: 250px;
  width: 100%;
  padding-top: 10px;
}

.f-s-16 {
  font-size: 16px !important;
}
.create-user-role-section {
  border: 2px solid $BackgroundLigthGray;
  padding: 5px 10px 10px 5px;
  border-radius: 6px;
  margin-top: 5px;
}

.border-l-r-none {
  border-left: none;
  border-right: none;
}
.border-t-none {
  border-top: none;
}
.border-b-t {
  border-top: 3px solid #1BA3AD;
  border-bottom: 3px solid #1BA3AD;
}
.bold {
  font-weight: bold !important;
}
.excel-btn{
  width:150px;
}
.main-div{
  width: 1250px;
}
.breadcrum-align{
  max-width: 700px;
  min-width: 700px;
  display: inline-block;
  vertical-align: top;
}
.practice-align{
  max-width: 300px;
  min-width: 300px;
  display: inline;
  float: right;
  vertical-align: middle;
}
.practice-text{
  margin-top: 10px;
}
.disable-section {
  pointer-events: none;
  opacity: 0.4;
}
.large-modal {
  width: 1100px !important;
}
.demo-wrapper {
  border: 1px solid #d1d1d1;
  height: 300px !important;
}
.demo-editor {
  height: 200px !important;
  border: 1px solid #f1f1f1;
  padding: 10px;
}
.rdw-editor-main {
  overflow: hidden !important;
}

.sidebar-container {
  span {
    font-family: "Economica";
    font-size: 14px !important;
  }

  width: 100%;
}